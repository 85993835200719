/** @jsxImportSource @emotion/react */
import "twin.macro";

import useConfirm from "@features/confirm";
import { LoadingButton } from "@features/ui";
import { RequestForQuote } from "@models/RequestForQuote";

import { useCancelRfqMutation } from "./data";

const CancelRfqButton = ({ rfq }: { rfq: RequestForQuote }) => {
  const cancelRfqMutation = useCancelRfqMutation();
  const confirm = useConfirm();

  const handleCancel = async () => {
    if (
      !(await confirm(
        rfq.status !== "sent"
          ? ""
          : "This will cancel all the quotes sent to suppliers.",
        {
          title: "Are you sure you want to cancel this RFQ?",
        }
      ))
    )
      return;
    cancelRfqMutation.mutate(rfq.id);
  };

  return (
    <LoadingButton
      variant="danger"
      color="secondary"
      onClick={handleCancel}
      loading={cancelRfqMutation.isPending}
    >
      Cancel RFQ
    </LoadingButton>
  );
};

export default CancelRfqButton;
