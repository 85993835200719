/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";

import { Skeleton } from "@mui/material";

import { OpaqueCard } from "@components/StyledComponents";
import { useFilterParams } from "@features/filters";

import { usePaginatedQuoteQuery } from "./data";

type QuotesByStatusFilterProps = {
  status: string;
  title: string;
};

const QuotesByStatusFilter: React.FC<QuotesByStatusFilterProps> = ({
  status,
  title,
}) => {
  const [filterParams, setFlterParams] = useFilterParams();
  const { isLoading, meta } = usePaginatedQuoteQuery(
    {
      filter: { status: status.split(",") },
      page: { size: 1 },
    },
    { refetchInterval: 30_000, staleTime: 0 }
  );
  const count = meta?.total_entries ?? null;

  const handleClick = () => {
    setFlterParams({ ...filterParams, status });
  };
  return (
    <OpaqueCard
      css={[
        tw`w-full max-w-xs p-4 transition-shadow hover:shadow-lg`,
        filterParams.status === status && tw`border-2 border-primary-800`,
      ]}
      role="button"
      tabIndex={-1}
      onClick={handleClick}
    >
      <h3 tw="mb-2 text-2xl font-thin text-neutral-700">{title}</h3>
      {isLoading && <Skeleton tw="w-32" />}
      {count === 0 && <p tw="text-neutral-600">No results</p>}
      {!!count && (
        <p tw="text-primary-800">
          Show {count} quote{count > 1 && "s"}
        </p>
      )}
    </OpaqueCard>
  );
};

export default QuotesByStatusFilter;
