/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useFormContext, useWatch } from "react-hook-form";

import WithPermission from "@components/Utility/WithPermission";
import { useOrderTypeOptions } from "@features/ordering";
import { useSuppliersListQuery } from "@features/suppliers";
import { HelperTextLabel } from "@features/ui";
import { useWarehouseOptions } from "@features/warehouses";
import {
  ControlledCheckboxInput,
  ControlledSelectInput,
  ControlledTextInput,
  ControlledSelectInput as SelectInput,
  intValidation,
} from "@utils/forms";
import useRoleIs from "@utils/useRoleIs";

import BlockCard from "../../../ui/BlockCard";

const TwoCol = tw.div`grid lg:grid-cols-2 gap-4`;

const unitOptions = [
  { id: "ea", name: "Each" },
  { id: "pack", name: "Pack" },
];

const LogisticBlock = () => {
  const { control, clearErrors } = useFormContext();
  const unitOfMeasure = useWatch({ name: "unitOfMeasure" });

  const roleIs = useRoleIs();
  const canEditCachedWarehouseQty = roleIs("super");

  const warehouseOptions = useWarehouseOptions();
  const optionalWarehouseOptions = [
    { id: "", name: "None" },
    ...warehouseOptions,
  ];

  const controlled = (name: string, rules?: any) => ({ name, control, rules });

  const isCustomizable = useWatch({ name: "isCustomizable" });
  const isBundle = useWatch({ name: "isBundle" });
  const orderType = useWatch({ name: "orderType" });

  const orderTypeOptions = useOrderTypeOptions();

  const { data } = useSuppliersListQuery();
  const supplierData = data ?? [];

  const isSingleDefaultVariant = useWatch({
    name: "variants.default.isActive",
  });

  return (
    <BlockCard title="Logistics" tw="space-y-4">
      <TwoCol>
        <SelectInput
          label={
            <HelperTextLabel title="Determines how the item will be ordered. For pre-orders, the item needs to be on an active program to be orderable.">
              Order Type *
            </HelperTextLabel>
          }
          options={orderTypeOptions}
          {...controlled("orderType", { required: true })}
          onChange={(e) => {
            if (e.target.value !== "inventory") {
              clearErrors("warehouse");
            }
          }}
        />
        <ControlledTextInput
          label="Lead Time (days)"
          placeholder="0"
          {...controlled("leadTimeInDays", intValidation)}
        />
      </TwoCol>
      <TwoCol>
        <SelectInput
          label="Unit of Measure *"
          options={unitOptions}
          {...controlled("unitOfMeasure", { required: true })}
        />
        <ControlledTextInput
          label="Pack Size"
          placeholder="1"
          disabled={unitOfMeasure === "ea"}
          type="number"
          {...controlled("packSize", intValidation)}
        />
      </TwoCol>
      <TwoCol>
        <ControlledTextInput
          label="Minimum Order Quantity (MOQ)"
          placeholder="0"
          {...controlled("minimumOrderQuantity", intValidation)}
        />
        <ControlledTextInput
          label="Reorder Threshold"
          placeholder="0"
          {...controlled("reorderThreshold", intValidation)}
        />
      </TwoCol>
      <TwoCol>
        <ControlledSelectInput
          label="Supplier *"
          resource="suppliers"
          options={supplierData?.map((s) => ({ id: s.id, name: s.name }))}
          {...controlled("supplierId", { required: true })}
        />
        <SelectInput
          label={"Warehouse" + (orderType === "inventory" ? " *" : "")}
          options={optionalWarehouseOptions}
          {...controlled("warehouse", { required: orderType === "inventory" })}
        />
      </TwoCol>
      <div>
        <ControlledCheckboxInput
          label={
            <HelperTextLabel title="If an item can be customized, users will be prompted while ordering to enter a customization for the item. Make sure the description is clear and descriptive so they know what they are customizing while they place their order.">
              Is Customizable
            </HelperTextLabel>
          }
          {...controlled("isCustomizable")}
        />
        {isCustomizable && (
          <ControlledTextInput
            label="Customization Description"
            {...controlled("customizationDescription")}
            multiline
          />
        )}
      </div>
      <WithPermission allow="super">
        <ControlledCheckboxInput
          tw="block -my-2"
          label={
            <HelperTextLabel title="By marking this item as a bundle, you can edit its variants' warehouse quantities">
              This is a bundle
            </HelperTextLabel>
          }
          {...controlled("isBundle")}
        />
        {/* 
          Items marked as a bundle need to pull from multiple SKUs to fulfill a single item. 
          By marking the item as a bundle, the system will no longer query the warehouse to 
          get the inventory qty and relies on super users keeping this value up-to-date when 
          new inventory is added.
        */}
        {isSingleDefaultVariant && isBundle && canEditCachedWarehouseQty && (
          <TwoCol>
            <ControlledTextInput
              label="Quantity On Hand"
              {...controlled("variants.default.quantityOnHand", intValidation)}
            />
          </TwoCol>
        )}
      </WithPermission>
    </BlockCard>
  );
};

export default LogisticBlock;
