/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Alert, Fade } from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
// @ts-ignore
import logoSelectWhiteColorUrl from "src/assets/logo-select-white-color.svg";

import { StyledButton } from "@components/StyledComponents";
import { useFilterParams } from "@features/filters";
import { loginWithToken } from "@redux/slices/user/currentUserSlice";
import client from "@services/api";

// https://developer.helpscout.com/docs-api/restricted-docs/

// Steps:
// users comes from helpscout redirect
// show security screen prompting user to login
// user logs in
// send request to back-end for JWT, sending along with the site the user is trying to access
// back-end can deny this request based on user's permissions
// if JWT is valid, redirect to the helpscout site, sending jwt, and returnTo as params

const validSlugs = ["test-knowledge-base"];
const subDomains = ["help", "supplier-help"];

const HelpScoutAuth = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [stateError, setStateError] = useState<null | string>(null);
  const [{ returnTo = "/", token: tokenFromParam }] = useFilterParams();

  const { loggedIn, loginIsLoading } = useSelector(
    (state: any) => state.currentUser
  );

  const {
    isAuthenticated,
    loginWithPopup,
    getAccessTokenSilently,
    getIdTokenClaims,
    isLoading,
  } = useAuth0();

  const { data: genJWT, error: jwtError } = useQuery({
    queryKey: ["helpscout-auth", { slug }],
    queryFn: () =>
      client.get<{ help_scout_token: string }>(`/help-scout-token`, {
        params: { site: slug } as any,
        deserializeOutput: false,
      }),
    enabled: isAuthenticated && loggedIn && !loginIsLoading,
    staleTime: 60_000,
  });

  const token = genJWT?.help_scout_token;
  const errorMessage =
    stateError || (jwtError as any)?.data?.error || jwtError?.message;

  useEffect(() => {
    if (!token || !slug || stateError) return;
    const domain = subDomains.includes(slug)
      ? "selectbrandhub.com"
      : "helpscoutdocs.com";

    if (tokenFromParam !== token) {
      setStateError(null);
      window.location.href = `https://${slug}.${domain}/authcallback?token=${token}&returnTo=${returnTo}`;
    } else {
      // helpscout sends back the token when it's invalid/expired
      // if it's the same as the one we generated, something went wrong!
      setStateError(
        "Token rejected by helpscout. Please contact help@selectbrandhub.com"
      );
    }
  }, [token, tokenFromParam, navigate, slug, returnTo, stateError]);

  useEffect(() => {
    // sets the token so that our requests are authenticated
    const getToken = async () => {
      const token = await getAccessTokenSilently();
      const claims = await getIdTokenClaims();
      dispatch(loginWithToken(token, new Date((claims!.exp ?? 1) * 1000)));
    };
    if (isAuthenticated && !isLoading) {
      getToken();
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    dispatch,
    isLoading,
  ]);

  useEffect(() => {
    if (![...validSlugs, ...subDomains].includes(slug!)) {
      setStateError("Invalid URL");
    }
  }, [slug, navigate]);

  const showForm = !!errorMessage || !loggedIn || loginIsLoading;

  return (
    <div tw="h-screen flex items-center justify-center">
      <div tw="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
        <Fade in={!showForm} enter>
          <div>
            <p tw="text-neutral-800 text-lg">
              Redirecting you to the knowledge base
            </p>
            <p tw="text-neutral-600">
              Not being redirected?{" "}
              <button
                tw="text-primary-800 underline"
                onClick={() => window.location.reload()}
              >
                Try again
              </button>
            </p>
          </div>
        </Fade>
      </div>
      <Fade in={showForm}>
        <div tw="rounded-xl shadow-lg bg-white overflow-hidden max-w-sm relative z-10">
          <div tw="bg-gradient-to-br from-neutral-900 to-neutral-700 p-6">
            <Link to="/">
              <img src={logoSelectWhiteColorUrl} tw="h-12 mx-auto" alt="Logo" />
            </Link>
          </div>
          {errorMessage && (
            <Alert severity="error" tw="text-lg rounded-lg m-6">
              {errorMessage}
            </Alert>
          )}
          {!errorMessage && (
            <div tw="px-6 py-8 text-center">
              <h2 tw="text-neutral-800 text-xl mb-6 leading-relaxed">
                Log in to brandhub to access the knowledge base.
              </h2>
              <StyledButton
                fullWidth
                cta
                onClick={() => loginWithPopup()}
                loading={isLoading}
              >
                Log in and continue
              </StyledButton>
            </div>
          )}
          <Link to="/" tw="block text-center mb-6 text-primary-700 underline">
            Go to back to brandhub
          </Link>
        </div>
      </Fade>
    </div>
  );
};

export default HelpScoutAuth;
