import { GlobalStyles } from "twin.macro";

import ReactDOM from "react-dom/client";
import Helmet from "react-helmet";
import { Provider } from "react-redux";

import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { Auth0Provider } from "@auth0/auth0-react";
import { SWRConfig } from "swr";

import ProtectedRouterProvider from "@features/routing";
import { store } from "@redux/store";
import AdapterUTCDateFns from "@utility/AdapterUTCDateFns";
import themeFile from "@utility/theme.js";
import { betterHash } from "@utils/betterHash";
import { QueryClientProviderWrapper } from "@utils/reactQuery";

import * as serviceWorker from "./serviceWorker";
import "./styles/main.css";

const theme = createTheme(themeFile);
const onRedirectCallback = (appState) => {
  window.history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const auth0ProviderConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  onRedirectCallback: onRedirectCallback,
  authorizationParams: {
    redirectUri: window.location.origin,
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Auth0Provider {...auth0ProviderConfig}>
      <SWRConfig
        value={{
          onErrorRetry: () => {},
          compare: (a, b) => betterHash(a) === betterHash(b),
        }}
      >
        <QueryClientProviderWrapper>
          <LocalizationProvider dateAdapter={AdapterUTCDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={theme}>
                <Helmet>
                  <meta charset="utf-8" />
                  <title>Brandhub</title>
                  <link rel="icon" href="/favicon.ico" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                  <meta name="theme-color" content="#000000" />
                  <link rel="apple-touch-icon" href="logo192.png" />
                  <link rel="manifest" href="/manifest.json" />
                  <meta
                    name="insight-app-sec-validation"
                    content="b0e47055-1c1d-42af-ac4c-c21e7b18370e"
                  />
                  <script
                    src="https://kit.fontawesome.com/327dd64aed.js"
                    crossorigin="anonymous"
                  ></script>
                </Helmet>
                <GlobalStyles />
                <ProtectedRouterProvider />
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </QueryClientProviderWrapper>
      </SWRConfig>
    </Auth0Provider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
