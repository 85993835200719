/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { Autocomplete, TextField } from "@mui/material";

import { useProgramListQuery } from "@features/programs";
import { Program } from "@models/Program";

import { StyledButton } from "../StyledComponents";
import { FilterRow } from "./FilterComponents";

interface FiltersBeaconReportProps {
  handleFilters: (value: any, filter: string, type: string) => void;
  handleSearch: () => void;
}

const FiltersBeaconReport = ({
  handleFilters,
  handleSearch,
}: FiltersBeaconReportProps) => {
  const isLoading = useSelector((state: any) => state.globalState.isLoading);

  const [searchText, setSearchText] = useState("");
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);

  const { data: programs } = useProgramListQuery({
    search: searchText,
  });

  return (
    <div tw="mt-3">
      <FilterRow>
        <Autocomplete
          value={selectedProgram}
          options={programs?.data || []}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => {
            setSelectedProgram(value);
            handleFilters(value, "programId", "beacon-location-report");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Program"
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
          size="small"
        />
        <StyledButton cta onClick={handleSearch} disabled={isLoading}>
          Generate report
        </StyledButton>
      </FilterRow>
    </div>
  );
};

export default FiltersBeaconReport;
