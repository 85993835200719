/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Alert } from "@mui/material";

import { OpaqueCard } from "@components/StyledComponents";
import { RfqSummary } from "@features/rfqs";
import { Quote } from "@models/Quote";

import CompletedQuote from "../PurchaserQuote/CompletedQuote";
import QuoteForm from "./QuoteForm";
import SupplierQuoteActions from "./SupplierQuoteActions";

type SupplierQuoteProps = {
  quote: Quote;
};

const SupplierQuote = ({ quote }: SupplierQuoteProps) => {
  return (
    <div tw="space-y-6">
      {quote.status === "accepted" && <QuoteForm quote={quote} />}
      {["complete", "awarded"].includes(quote.status) && (
        <OpaqueCard tw="p-6">
          <CompletedQuote quote={quote} />
        </OpaqueCard>
      )}
      {["sent", "declined", "canceled"].includes(quote.status) && (
        <>
          <OpaqueCard tw="p-6 space-y-6">
            <RfqSummary
              rfq={quote.requestForQuote}
              pricingTierQuotes={quote.pricingTierQuotes}
            />
            {quote.status === "declined" && (
              <Alert severity="error">You declined this quote.</Alert>
            )}
            {quote.status === "canceled" && (
              <Alert severity="error">
                This quote was canceled by the client.
              </Alert>
            )}
            {quote.status === "sent" && <SupplierQuoteActions quote={quote} />}
          </OpaqueCard>
        </>
      )}
    </div>
  );
};

export default SupplierQuote;
