/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { DeleteRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import ReactQueryTable from "@components/Table/ReactQueryTable";
import { HelperTextLabel } from "@features/ui";
import {
  ControlledTextInput,
  moneyAdornment,
  moneyValidation,
} from "@utils/forms";

const CostTable = () => {
  const { setValue, control } = useFormContext();
  const costKey = `costs`;
  const costs = useWatch({ name: costKey, defaultValue: [] });

  const handleDelete = (index) => {
    const newCosts = [...costs.slice(0, index), ...costs.slice(index + 1)];
    setValue(costKey, newCosts, { shouldDirty: true });
  };

  useEffect(() => {
    if (costs.length === 0 || costs.at(-1).name !== "") {
      setValue(costKey, [...costs, { name: "", amount: "", type: "cost" }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costs]);

  return (
    <ReactQueryTable
      rows={costs}
      columns={[
        {
          id: "name",
          label: (
            <HelperTextLabel title="E.g. Estimated Shipping Cost" icon="info">
              Set-up Costs
            </HelperTextLabel>
          ),
          render: (_v, _r, i) => (
            <ControlledTextInput
              name={`${costKey}.${i}.name`}
              control={control}
              rules={{ required: i < costs.length - 1 }}
            />
          ),
        },
        {
          id: "amount",
          label: "Total Cost",
          render: (_v, _r, i) => (
            <ControlledTextInput
              name={`${costKey}.${i}.amount`}
              InputProps={moneyAdornment}
              control={control}
              placeholder="123.00"
              rules={{ required: i < costs.length - 1, ...moneyValidation }}
            />
          ),
        },
        {
          id: "remove",
          label: "",
          render: (_v, _r, i) =>
            i === costs.length - 1 ? null : (
              <IconButton
                onClick={() => handleDelete(i)}
                title="Delete"
                tw="text-neutral-400 hover:text-neutral-600 focus:text-neutral-600 -mx-3"
              >
                <DeleteRounded fontSize="small" />
              </IconButton>
            ),
        },
      ]}
    />
  );
};

export default CostTable;
