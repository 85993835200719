import { useSelector } from "react-redux";
import { RouteObject, RouterProvider } from "react-router-dom";

import { UserRole } from "@models/UserRole";
import { sentryCreateBrowserRouterV6 } from "@services/sentry";

import FourOhFour from "../../pages/FourOhFour";
import root from "./routes/root";
import { protectedRoute } from "./types";

// Recursively filters protectedRoutes based on the user's role
// If the user is not allowed to access a route, return a 404 page without children,
// otherwise, recursively protect child routes
const protectRoutes = (
  role: UserRole,
  routes: protectedRoute[]
): RouteObject[] =>
  routes.map((route) => {
    if (!route.allowedRoles || route.allowedRoles.includes(role)) {
      return {
        ...route,
        // Recursively protect child routes
        children: protectRoutes(
          role,
          route.children?.map((child) => ({
            ...child,
            allowedRoles:
              child.allowedRoles ?? route.defaultChildrenAllowedRoles,
          })) || []
        ),
      } as RouteObject;
    }
    return {
      ...route,
      element: <FourOhFour />,
    } as RouteObject;
  });

const ProtectedRouterProvider = (props) => {
  const { role } = useSelector((state: any) => state.currentUser);
  const protectedRouter = sentryCreateBrowserRouterV6(
    protectRoutes(role, root),
    {
      future: {
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
        v7_relativeSplatPath: true,
      },
    }
  );
  return <RouterProvider router={protectedRouter} {...props} />;
};

export default ProtectedRouterProvider;
