/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { DenyOrderModal } from "@features/appovals";
import useConfirm from "@features/confirm";
import {
  deleteOrderSet,
  deleteOrderSetOrder,
  deleteOrderSetVariant,
  denyOrderSetOrder,
  setRebuildRef,
} from "@redux/slices/orders/currentOrderSetSlice";

import WarningModal from "../../Utility/Modals/WarningModal";
import OrderDetailModal from "../OrderDetailModal";
import MemoInputCell from "./MemoInputCell";
import SetTableHead from "./SetTableHead";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.orderTable,
}));

const OrderSetTable = ({
  isLoading,
  setVariants,
  orders,
  orderType,
  osId,
  orderSetStatus,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const setConfirm = useConfirm();

  const [refTable, setRefTable] = useState(null);
  const [variantLength, setVariantLength] = useState(0);
  const [isEditOpen, setEditOpen] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [denyOrderModalId, setDenyOrderModalId] = useState(null);
  const [currentWarning, setCurrentWarning] = useState({
    warning: null,
    arg: null,
    func: null,
  });

  const [headerHeight, setHeaderHeight] = useState(0);

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const patchLoading = useSelector((state) => state.patchOrder.isLoading);

  const rebuildRef = useSelector((state) => state.currentOrderSet.rebuildRef);

  const handleEditOpen = (id) => {
    setCurrentOrderId(id);
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setCurrentOrderId(null);
  };

  const handleWarningOpen = (message, arg, func) => {
    setCurrentWarning({ warning: message, arg: arg, func: func });
    setIsWarningOpen(true);
  };

  const handleWarningClose = () => {
    setIsWarningOpen(false);
    setCurrentWarning({ warning: null, arg: null, func: null });
  };

  const handleDeleteSetVariant = (args) => {
    if (setVariants.length === 1) {
      dispatch(deleteOrderSet({ id: osId, type: orderType }));
    } else {
      dispatch(deleteOrderSetVariant(args));
    }
  };

  const handleDeleteOrder = async (id) => {
    if (orderSetStatus === "inactive") {
      // No values have been entered, so no need to confirm
      dispatch(deleteOrderSetOrder(id));
    } else if (orderSetStatus === "draft") {
      const confirmed = await setConfirm(
        `Are you sure you want to delete this order? (#${id})`,
        {
          title: "Delete Order",
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
        }
      );
      if (!confirmed) return;
      dispatch(deleteOrderSetOrder(id));
    } else {
      const confirmed = await setConfirm(
        `Are you sure you want to deny this order? (#${id})`,
        {
          title: "Deny Order",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }
      );
      if (!confirmed) return;
      setDenyOrderModalId(id);
    }
  };

  const handleDenyOrder = (id, note) => {
    dispatch(denyOrderSetOrder(id, note));
  };
  const handleKeyDown = useCallback(
    (ref, key) => {
      let keys = Object.keys(refTable);
      let currentIndex = keys.indexOf(ref);
      if (key === "Enter" || key === "ArrowDown") {
        if (keys.length - (currentIndex + 1) >= variantLength) {
          return (
            refTable[
              keys[currentIndex + variantLength]
            ]?.current?.firstChild.focus() ?? null
          );
        } else return null;
      } else if (key === "ArrowUp") {
        if (currentIndex + 1 >= variantLength) {
          return (
            refTable[
              keys[currentIndex - variantLength]
            ]?.current?.firstChild.focus() ?? null
          );
        }
      } else if (key === "ArrowLeft") {
        if (currentIndex !== 0) {
          return (
            refTable[keys[currentIndex - 1]]?.current?.firstChild.focus() ??
            null
          );
        }
      } else if (key === "ArrowRight") {
        if (currentIndex !== keys.length - 1) {
          return (
            refTable[keys[currentIndex + 1]]?.current?.firstChild.focus() ??
            null
          );
        }
      }
    },
    [variantLength, refTable]
  );

  useEffect(() => {
    if (
      (orders && !refTable) ||
      (orders.length > 0 &&
        Object.keys(refTable).length > 0 &&
        `${orders[0].id}` !== Object.keys(refTable)[0].split("-")[0]) ||
      Object.keys(refTable).length !== orders.length * setVariants.length ||
      rebuildRef
    ) {
      if (orders.length !== 0) {
        if (rebuildRef) {
          dispatch(setRebuildRef({ value: false }));
        }
        let refs = {};

        orders.forEach((order) => {
          setVariants.forEach((v) => {
            refs[`${order.id}-${v.sku}`] = React.createRef(null);
          });
        });

        setRefTable(refs);
      }
    }
  }, [orders, refTable, setVariants, rebuildRef, dispatch]);

  useEffect(() => {
    if (
      (setVariants && setVariants.length > 0 && variantLength === 0) ||
      variantLength !== setVariants.length
    ) {
      setVariantLength(setVariants.length);
    }
  }, [variantLength, setVariants, setVariants.length]);

  if (isLoading || (!refTable && orders.length > 0)) {
    return <CircularProgress tw="m-4" />;
  }

  return (
    <>
      {denyOrderModalId && (
        <DenyOrderModal
          orderId={denyOrderModalId}
          handleClose={() => setDenyOrderModalId(null)}
          handleDeny={handleDenyOrder}
        />
      )}
      {isEditOpen && currentOrderId && (
        <OrderDetailModal
          open={isEditOpen}
          handleClose={handleEditClose}
          orderId={currentOrderId}
        />
      )}
      {isWarningOpen && currentWarning.warning && (
        <WarningModal
          {...currentWarning}
          handleClose={handleWarningClose}
          open={isWarningOpen}
        />
      )}
      <TableContainer
        ref={tableRef}
        style={{ "--table-head-height": `${headerHeight}px` }}
        tw="max-h-[max(calc(var(--table-head-height) + 120px), calc(calc(100vh - var(--header-height)) - 220px))]"
      >
        <Table
          stickyHeader={true}
          size="small"
          aria-label="pre-order-table"
          tw="border-separate"
        >
          {setVariants.length === 0 ? (
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{ root: classes.root }}
                  style={{ width: "300px" }}
                  className={classes.borderRight}
                ></TableCell>
                <TableCell classes={{ root: classes.root }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      className={classes.bodyText}
                      style={{ marginRight: "10px" }}
                    >
                      There are no items currently in this order...
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
          ) : (
            <>
              <SetTableHead
                classes={classes}
                measuredRef={measuredRef}
                handleWarning={handleWarningOpen}
                handleDeleteSetVariant={handleDeleteSetVariant}
              />
              <TableBody>
                {orders.map((ord) => (
                  <TableRow key={ord.id}>
                    <TableCell
                      classes={{ root: classes.noPadCell }}
                      className={classes.borderRight}
                      css={[
                        tw`sticky left-0 z-10 bg-primary-100 border-primary-300`,
                        ord.status === "canceled" &&
                          tw`bg-red-200 border-red-300 `,
                      ]}
                    >
                      <div tw="relative w-full h-fit">
                        <div
                          tw="flex items-center justify-between w-full
                              absolute top-0 left-0 h-full px-2"
                        >
                          <Tooltip
                            placement="right"
                            title={`${ord.address.streetOne}, ${
                              ord.addressStreetTwo
                                ? `${ord.address.streetTwo}, `
                                : ""
                            } ${ord.address.city}, ${
                              ord.address.stateOrRegion
                            }, ${ord.address.zip}, ATTN: ${ord.attn || "---"}`}
                          >
                            <Typography
                              css={[
                                tw`pl-2 font-medium`,
                                ord.status === "canceled" &&
                                  tw`text-red-900 line-through`,
                              ]}
                              noWrap
                            >
                              {`${ord.address.name}: ${ord.address.city}, ${ord.address.stateOrRegion}`}
                            </Typography>
                          </Tooltip>
                          <div style={{ display: "flex" }}>
                            <Tooltip
                              title={
                                ord.status === "draft"
                                  ? "Delete Order"
                                  : "Deny Order"
                              }
                            >
                              <IconButton
                                onClick={() => handleDeleteOrder(ord.id)}
                                disabled={
                                  patchLoading || ord.status === "canceled"
                                }
                                size="large"
                              >
                                <CancelIcon fontSize="small" color="inherit" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Edit Attention & Delivery Notes">
                              <IconButton
                                onClick={() => {
                                  handleEditOpen(ord.id);
                                }}
                                size="large"
                              >
                                <EditIcon fontSize="small" color="inherit" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    {ord.variants.map((v, index) => (
                      <MemoInputCell
                        key={v.id}
                        sku={v.sku}
                        id={v.id}
                        index={index}
                        orderId={ord.id}
                        cellRef={refTable[`${ord.id}-${v.sku}`]}
                        handleKeyDown={handleKeyDown}
                        packSize={v.item.packSize}
                        ref={tableRef}
                        disabled={
                          ord.status === "canceled" || ord.status === "approved"
                        }
                      />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

OrderSetTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setVariants: PropTypes.array,
  orders: PropTypes.array,
  orderType: PropTypes.string,
  osId: PropTypes.string,
};

export default React.memo(OrderSetTable, (prev, next) => {
  return (
    prev.orders.length === next.orders.length &&
    prev.isLoading === next.isLoading &&
    prev.setVariants.length === next.setVariants.length &&
    prev.orders === next.orders &&
    prev.orderType === next.orderType &&
    prev.osId === next.osId
  );
});
