/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Chip } from "@mui/material";

import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import { DateFromNow } from "@features/ui";
import { Quote } from "@models/Quote";
import { utcToLocalDate } from "@utility/utilityFunctions";

import QuoteStatus from "./QuoteStatus";

const quoteUrl = (quote: Quote) =>
  ["complete", "awarded"].includes(quote.status) ? `/quotes/${quote.id}` : null;

const QuotesTable = ({
  ...tableProps
}: PaginatedResourceProps & {
  rows: Quote[];
}) => {
  const columns: Column<Quote>[] = [
    {
      id: "supplierName",
      label: "Supplier",
      to: quoteUrl,
    },
    {
      id: "status",
      label: "Status",
      to: quoteUrl,
      render: (status) => <QuoteStatus status={status} />,
    },
    {
      id: "updatedAt",
      label: "Last Action Date",
      to: quoteUrl,
      render: (d, { status }) =>
        status === "sent" ? (
          <span tw="text-neutral-500">---</span>
        ) : (
          <DateFromNow date={utcToLocalDate(d)} />
        ),
    },
    {
      id: "_action",
      label: "",
      to: quoteUrl,
      render: (_, { status }) =>
        ["complete", "awarded"].includes(status) ? (
          <Chip label="View" variant="outlined" size="small" clickable />
        ) : null,
    },
  ];

  return <ReactQueryTable {...tableProps} columns={columns} />;
};

export default QuotesTable;
