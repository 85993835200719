import { createSlice } from "@reduxjs/toolkit";

import { buildFilters } from "../../../api/apiFunctions";
import { axiosGet } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalStateSlice";
import {
  mapAllocationSnapshotReport,
  mapBeaconLocationReport,
  mapBudgetAdjustmentReport,
  mapBudgetSummaryExportReport,
  mapInventorySnapshotReport,
  mapInventoryVelocityReport,
  mapOrderWindowDetail,
  mapOrderWindowSummary,
  mapPreOrderUserReport,
  mapProductListReport,
  mapPromoCodeReport,
  mapShippedOrdersReport,
  mapVariantAllocationReport,
} from "./maps";

const reportMapping = {
  "order-window-summary": mapOrderWindowSummary,
  "order-window-detail": mapOrderWindowDetail,
  "budget-adjustment-report": mapBudgetAdjustmentReport,
  "budget-summary-export-report": mapBudgetSummaryExportReport,
  "inventory-snapshot": mapInventorySnapshotReport,
  "inventory-velocity": mapInventoryVelocityReport,
  "pre-order-user-report": mapPreOrderUserReport,
  "promo-code-report": mapPromoCodeReport,
  "variant-allocation-report": mapVariantAllocationReport,
  "shipped-orders-report": mapShippedOrdersReport,
  "product-list-report": mapProductListReport,
  "allocation-snapshot-report": mapAllocationSnapshotReport,
  "beacon-location-report": mapBeaconLocationReport,
};

let initialState = {
  isLoading: false,
  reportType: null,
  reportData: [],
  hasGenerated: false,
  error: null,
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getReportsSuccess(state, action) {
      const { type, reportData } = action.payload;
      state.reportType = type;
      state.reportData = reportData;
      state.hasGenerated = true;
      state.error = null;
      state.isLoading = false;
    },
    clearReports(state) {
      state.isLoading = false;
      state.reportType = null;
      state.reportData = [];
      state.error = null;
    },
    setGenerated(state, action) {
      const { value } = action.payload;
      state.hasGenerated = value;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  getReportsSuccess,
  clearReports,
  setGenerated,
  setFailure,
} = reportSlice.actions;

export default reportSlice.reducer;

export const fetchReport =
  (filters, categories = null) =>
  async (dispatch, getState) => {
    const variantCategories = getState().variantCategories.categories;
    const variantOptions = variantCategories.flatMap((cat) => cat.variants);
    try {
      dispatch(startGlobalLoad());
      dispatch(setIsLoading());
      let queryString = buildFilters(
        filters,
        "",
        "",
        `/api/reports/${filters.reportType}`
      );
      const response = await axiosGet(queryString, false);
      if (response.error) throw (await response).error;
      const mappedData = reportMapping[filters.reportType](
        response.data,
        categories,
        variantOptions
      );
      dispatch(
        getReportsSuccess({ type: filters.reportType, reportData: mappedData })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    }
  };
