import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import { formatMoney, upCase } from "@utility/utilityFunctions";

import Logo from "../../assets/brandhublogowhite.png";
import generalStyles from "./generalStyles";

Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
});

const styles = StyleSheet.create({
  ...generalStyles,
  titleSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 504,
    height: 42,
    borderBottom: "2 solid #0099cc",
    backgroundColor: "black",
  },
  footerSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "0 10px",
    alignItems: "center",
    width: 504,
    height: 57,
    borderBottom: "2 solid #0099cc",
    backgroundColor: "black",
  },
  footerTotals: {
    width: 200,
    display: "flex",
    flexDirection: "column",
  },
  footerRow: {
    width: 200,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    height: 30,
    width: "auto",
    marginLeft: 10,
  },
  infoSection: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 0 10px 0",
    boxSizing: "border-box",
    width: 504,
    minHeight: 170,
    borderBottom: "2 solid #0099cc",
    flexShrink: 1,
  },
  infoPanel: {
    display: "flex",
    flexDirection: "column",
    padding: "5",
    boxSizing: "border-box",
    width: 250,
    border: "1 solid #0099cc",
    height: "100%",
  },
  infoBreak: {
    width: "100%",
    height: 1,
    marginBottom: 15,
    backgroundColor: "#0099cc",
  },
  itemDetailSection: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0 10px 0",
    boxSizing: "border-box",
    width: 504,
    borderBottom: "2 solid #0099cc",
  },
  itemDetailRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    height: 25,
  },
  skuBlock: {
    width: 75,
    height: 25,
  },
  nameBlock: {
    width: 112,
    height: 25,
  },
  variantBlock: {
    width: 112,
    height: 25,
  },
  packSizeBlock: {
    width: 40,
    height: 25,
  },
  qtyBlock: {
    width: 40,
    height: 25,
  },
  costBlock: {
    width: 75,
    height: 25,
  },
  totalBlock: {
    width: 50,
    height: 25,
    textAlign: "right",
  },
  setUpDescBlock: {
    width: 304,
    height: 25,
  },
});

const PurchaseOrderPDF = ({ purchaseOrder, preferExternalId }) => {
  let purchaseOrderVariants = purchaseOrder.purchaseOrderVariants.filter(
    (pov) => !pov.isSetupFee
  );
  let setUpFees = purchaseOrder.purchaseOrderVariants.filter(
    (pov) => pov.isSetupFee
  );

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.titleSection}>
          <Image style={styles.logo} source={Logo} />
          <Text style={{ ...styles.titleText, marginRight: 10 }}>
            {`Purchase Order ${purchaseOrder.id}`}
          </Text>
        </View>
        <View style={styles.infoSection}>
          {purchaseOrder.type === "fulfillment" ? (
            <>
              <View style={styles.infoPanel}>
                <Text style={styles.headerText}>Inventory Fulfillment</Text>
                <View style={styles.infoBreak} />
                <Text
                  style={styles.bodyText}
                >{`Purchased By: ${purchaseOrder.purchaser}`}</Text>
                <Text style={styles.bodyText}>{`Status: ${upCase(
                  purchaseOrder.status,
                  "-"
                )}`}</Text>
              </View>
              <View style={styles.infoPanel}>
                <Text style={styles.headerText}>Fulfillment Notes:</Text>
                <View style={styles.infoBreak} />
                <Text
                  style={styles.bodyText}
                >{`In Market Date: ${purchaseOrder.inMarketDate}`}</Text>
                <Text style={styles.bodyText}>{`Warehouse(s): ${[
                  ...new Set(
                    purchaseOrder.purchaseOrderVariants.map((pov) =>
                      upCase(pov.item.warehouse)
                    )
                  ),
                ].join(", ")}`}</Text>
                <Text style={styles.bodyText}>
                  This fulfillment is complete, but will continue to update with
                  shipping information as the warehouse updates ship dates and
                  tracking information for the orders.
                </Text>
              </View>
            </>
          ) : (
            <>
              <View style={styles.infoPanel}>
                <Text style={styles.headerText}>Detail:</Text>
                <View style={styles.infoBreak} />
                <Text style={styles.bodyText}>{`Supplier: ${
                  purchaseOrder.supplier ?? "---"
                }`}</Text>
                <Text
                  style={styles.bodyText}
                >{`Purchased By: ${purchaseOrder.purchaser}`}</Text>
                <Text style={styles.bodyText}>{`Supplier Reference: ${
                  purchaseOrder.supplierReference ?? "---"
                }`}</Text>
                <Text style={styles.bodyText}>{`Invoice Number: ${
                  purchaseOrder.invoiceNumber ?? "---"
                }`}</Text>
                <Text style={styles.bodyText}>{`Terms: ${
                  purchaseOrder.terms ?? "---"
                }`}</Text>
                <Text style={styles.bodyText}>{`Status: ${upCase(
                  purchaseOrder.status,
                  "-"
                )}`}</Text>
              </View>
              <View style={styles.infoPanel}>
                <Text style={styles.headerText}>Supplier Notes:</Text>
                <View style={styles.infoBreak} />
                <Text
                  style={styles.bodyText}
                >{`In Market Date: ${purchaseOrder.inMarketDate}`}</Text>
                <Text style={styles.bodyText}>{`Shipping Method: ${
                  purchaseOrder.method ?? "---"
                }`}</Text>
                <Text style={styles.bodyText}>{`Labeling Instructions: ${
                  purchaseOrder.labelingInstructions ?? "---"
                }`}</Text>
                <Text style={styles.bodyText}>{`Note: ${
                  purchaseOrder.note ?? "---"
                }`}</Text>
              </View>
            </>
          )}
        </View>
        <View style={styles.itemDetailSection}>
          <View style={styles.itemDetailRow}>
            <View style={styles.skuBlock}>
              <Text style={styles.headerText}>
                {preferExternalId ? "Product ID" : "Sku"}
              </Text>
            </View>
            <View style={styles.nameBlock}>
              <Text style={styles.headerText}>Name</Text>
            </View>
            <View style={styles.variantBlock}>
              <Text style={styles.headerText}>Variant</Text>
            </View>
            <View style={styles.packSizeBlock}>
              <Text style={styles.headerText}>Pack</Text>
            </View>
            <View style={styles.qtyBlock}>
              <Text style={styles.headerText}>Qty</Text>
            </View>
            <View style={styles.costBlock}>
              <Text style={styles.headerText}>Cost</Text>
            </View>
            <View style={styles.totalBlock}>
              <Text style={styles.headerText}>Total</Text>
            </View>
          </View>
          {purchaseOrderVariants.map((pov, i) => (
            <View style={styles.itemDetailRow} key={i}>
              <View style={styles.skuBlock}>
                <Text style={styles.smText}>
                  {preferExternalId ? pov.warehouseId : pov.sku}
                </Text>
              </View>
              <View style={styles.nameBlock}>
                <Text style={styles.smText}>{pov.item.name}</Text>
              </View>
              <View style={styles.variantBlock}>
                <Text style={styles.smText}>{pov.variantNames}</Text>
              </View>
              <View style={styles.packSizeBlock}>
                <Text style={styles.smText}>{pov.packSize}</Text>
              </View>
              <View style={styles.qtyBlock}>
                <Text style={styles.smText}>{pov.qty}</Text>
              </View>
              <View style={styles.costBlock}>
                <Text style={styles.smText}>
                  {formatMoney(pov.actualCost, false)}
                </Text>
              </View>
              <View style={styles.totalBlock}>
                <Text style={styles.smText}>
                  {formatMoney(pov.actualCost * pov.qty, false)}
                </Text>
              </View>
            </View>
          ))}
          {setUpFees &&
            setUpFees.length > 0 &&
            setUpFees.map((suf, i) => (
              <View style={styles.itemDetailRow} key={i}>
                <View style={styles.skuBlock}>
                  <Text style={styles.smText}>Set Up Fee</Text>
                </View>
                <View style={styles.setUpDescBlock}>
                  <Text style={styles.smText}>{suf.setupFeeDesc}</Text>
                </View>
                <View style={styles.costBlock}>
                  <Text style={styles.smText}>
                    {formatMoney(suf.actualCost, false)}
                  </Text>
                </View>
                <View style={styles.totalBlock}>
                  <Text style={styles.smText}>
                    {formatMoney(suf.actualCost, false)}
                  </Text>
                </View>
              </View>
            ))}
        </View>
        <View style={styles.footerSection}>
          <Text style={styles.titleText}>Totals</Text>
          <View style={styles.footerTotals}>
            {purchaseOrder.type === "fulfillment" ? (
              <View style={styles.footerRow}>
                <Text style={{ ...styles.headerText, color: "white" }}>
                  Total:
                </Text>
                <Text style={{ ...styles.headerText, color: "white" }}>
                  {formatMoney(purchaseOrder.total, false)}
                </Text>
              </View>
            ) : (
              <>
                <View style={styles.footerRow}>
                  <Text style={{ ...styles.bodyText, color: "white" }}>
                    Total Freight:
                  </Text>
                  <Text style={{ ...styles.bodyText, color: "white" }}>
                    {formatMoney(purchaseOrder.totalFreight, false)}
                  </Text>
                </View>
                <View style={styles.footerRow}>
                  <Text style={{ ...styles.bodyText, color: "white" }}>
                    Tax:
                  </Text>
                  <Text style={{ ...styles.bodyText, color: "white" }}>
                    {formatMoney(purchaseOrder.totalTax, false)}
                  </Text>
                </View>
                <View style={styles.footerRow}>
                  <Text
                    style={{
                      ...styles.headerText,
                      color: "white",
                      marginBottom: 0,
                    }}
                  >
                    Total:
                  </Text>
                  <Text
                    style={{
                      ...styles.headerText,
                      color: "white",
                      marginBottom: 0,
                    }}
                  >
                    {formatMoney(purchaseOrder.total, false)}
                  </Text>
                </View>
              </>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PurchaseOrderPDF;
