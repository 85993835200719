import * as Sentry from "@sentry/react";
import { xor } from "lodash";
import {
  axiosGet,
  axiosGetWithNext,
  axiosPatch,
  axiosPost,
} from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";
import { kebabCaseKeys, separateByComma } from "@utility/utilityFunctions";
import permissions from "@utils/permissions";

import { loginWithAuthToken } from "../../../api/userApi";
import { mapchannelUsers } from "../channelSlice";
import { fetchCurrentUserCostCenters } from "../costCenterSlice";
import { setError } from "../errorSlice";
import { setProductView, setRedirect } from "../globalStateSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import {
  buildDashboardOptionPatch,
  buildFavoriteItemPatch,
  buildOrgPatch,
} from "./helpers";
import { mapDashboardOptions, mapOrgs, mapUser } from "./maps";

const fieldMap = {
  "has-viewed-dashboard": "hasViewedDashboard",
  "has-viewed-address-book": "hasViewedAddressBook",
  "has-viewed-pre-order": "hasViewedPreOrder",
  "has-viewed-order-set": "hasViewedOrderSet",
};

let initialState = {
  loginIsLoading: false,
  isLoading: false,
  isUpdateLoading: false,
  loggedIn: false,
  id: "",
  firstName: "",
  lastName: "",
  name: "",
  initials: "",
  email: "",
  role: "",
  status: "",
  sessionExpire: null,
  loginType: null,
  channels: [],
  channelUsers: [],
  currentChannelId: "",
  territories: [],
  currentTerritoryId: "",
  defaultTerritoryId: null,
  groups: [],
  favoriteItems: [],
  favoriteItemIds: [],
  budgets: [],
  costCenters: [],
  hasViewedDashboard: false,
  hasViewedAddressBook: false,
  hasViewedPreOrder: false,
  hasViewedOrderSet: false,
  supplierCounts: {
    new: null,
    inProgress: null,
  },
  organization: {
    id: null,
    name: null,
    logoUrl: null,
    usesApprovalFlow: null,
    usesApprovalThreshold: null,
    usesChannels: null,
    approvalThreshold: null,
    usesOnDemand: null,
    usesInventory: null,
    preferExternalId: null,
    preferProductGridView: null,
    usesItemCustomization: null,
    budgetLocation: null,
    budgetCategoryId: null,
    includeShippingInBudgets: null,
    includeTaxesInBudgets: null,
    estimatedShippingPercentage: 0.18,
    minimumShippingCost: 9,
    addressBookType: null,
    usesStripe: null,
    stripeAccount: null,
    includeShippingInStripeCharge: null,
    includeTaxesInStripeCharge: null,
    allocationLocation: null,
    orderLimitType: null,
    countries: null,
    dashboards: null,
    availableOrgs: null,
    warehouses: [],
    approvalFlows: [],
    hasPurchasingAgents: false,
  },
  supplier: null,
  hasFetchedOrgData: false,
  refetchData: false,
  isChannelLocked: false,
  loginError: null,
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setLoginLoading(state) {
      state.loginIsLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setLoginSuccess(state, action) {
      const { expires, type } = action.payload;
      state.sessionExpire = expires;
      state.loginIsLoading = false;
      state.loggedIn = true;
      state.loginType = type;
      state.error = null;
    },
    getUserSuccess(state, action) {
      const { user, organization, orgId } = action.payload;

      Sentry.setUser({ id: user.id, email: user.email });
      Sentry.setTag("user_role", user.role);
      if (organization) Sentry.setTag("organization", organization.name);

      state = Object.assign(state, user);

      const isValidChannelId = user.channels.some(
        (channel) => channel.id === user.defaultChannelId
      );

      state.currentChannelId = isValidChannelId
        ? user.defaultChannelId
        : user.channels[0]?.id || null;

      const isValidTerritoryId = user.territories.some(
        (territory) => territory.id === user.defaultTerritoryId
      );

      state.currentTerritoryId = isValidTerritoryId
        ? user.defaultTerritoryId
        : user.territories[0]?.id || null;

      if (organization) {
        state.organization = { ...organization };
      }

      if (orgId) {
        let currentOrgs = [...state.organization.availableOrgs];
        let newOrg = currentOrgs.find((org) => org.id === orgId);
        state.organization.id = newOrg.id;
        state.organization.name = newOrg.name;
        state.organization.logoUrl = newOrg.logoUrl;
      }

      state.isLoading = false;
      state.loggedIn = true;
      state.loginError = null;
    },
    updateUserSuccess(state, action) {
      const { user } = action.payload;
      state = Object.assign(state, user);
      state.isLoading = false;
    },
    updateOrgSuccess(state, action) {
      const { org } = action.payload;
      const updatedAvailableOrgs = state.organization.availableOrgs.map((o) =>
        o.id === org.id ? org : o
      );
      state.organization = {
        ...org,
        availableOrgs: updatedAvailableOrgs,
      };
      state.isUpdateLoading = false;
    },
    updateDashboardOptionsSuccess(state, action) {
      const { options, role } = action.payload;
      let newOptionIds = options.map((o) => o.id);
      state.organization.dashboards = state.organization.dashboards.map((d) => {
        if (d.role === role) {
          let newOptions = d.options.map((o) => {
            if (newOptionIds.includes(o.id)) {
              let newOption = options.find((opt) => opt.id === o.id);
              if (newOption) return newOption;
              else return o;
            } else return o;
          });
          return {
            ...d,
            options: newOptions,
          };
        } else return d;
      });
      state.isUpdateLoading = false;
    },
    updateFavoriteItemsSuccess(state, action) {
      const { items } = action.payload;
      state.favoriteItems = [...items];
      state.isUpdateLoading = false;
    },
    updateCurrentTerritory(state, action) {
      const { territory } = action.payload;
      state.currentTerritoryId = territory;
    },
    updateCurrentChannel(state, action) {
      const { channel } = action.payload;
      state.currentChannelId = channel;
    },
    updateHasViewedSuccess(state, action) {
      const { field } = action.payload;
      state[fieldMap[field]] = true;
      state.isUpdateLoading = false;
    },
    updateSupplierCounts(state, action) {
      const { newCount, inProgressCount } = action.payload;
      let initialNewCount = state.supplierCounts.new ?? 0;
      let initialInProgressCount = state.supplierCounts.inProgress ?? 0;
      state.supplierCounts.new = initialNewCount + newCount;
      state.supplierCounts.inProgress =
        initialInProgressCount + inProgressCount;
    },
    setHasFetchedData(state, action) {
      const { value } = action.payload;
      state.hasFetchedOrgData = value;
    },
    setIsChannelLocked(state, action) {
      const { value } = action.payload;
      state.isChannelLocked = value;
    },
    setExpires(state, action) {
      const { expires } = action.payload;
      state.sessionExpire = expires;
    },
    removeUser: (state) => {
      Object.assign(state, initialState);

      // Clears user tag from Sentry
      Sentry.setUser(null);
    },
    setRefetchData(state, action) {
      const { value } = action.payload;
      state.refetchData = value;
    },
    setLogInFailure(state, action) {
      const { error } = action.payload;
      state.loginIsLoading = false;
      state.loginError = error;
    },
    setFailure(state) {
      state.isLoading = false;
      state.loginIsLoading = false;
      state.isUpdateLoading = false;
    },
    updateChannelUserSuccess(state, action) {
      const newUc = action.payload;
      state.channelUsers = state.channelUsers.map((uc) =>
        uc.id === newUc.id ? newUc : uc
      );
      state.isLoading = false;
    },
  },
});

export const {
  setIsLoading,
  setLoginLoading,
  setIsUpdateLoading,
  getUserSuccess,
  updateUserSuccess,
  setLoginSuccess,
  updateOrgSuccess,
  updateFavoriteItemsSuccess,
  setRedirectLink,
  setExpires,
  updateCurrentTerritory,
  updateCurrentChannel,
  updateSupplierCounts,
  updateDashboardOptionsSuccess,
  updateHasViewedSuccess,
  removeUser,
  setHasFetchedData,
  setRefetchData,
  setIsChannelLocked,
  setLogInFailure,
  setUpdateFailure,
  setFailure,
  updateChannelUserSuccess,
} = currentUserSlice.actions;

export default currentUserSlice.reducer;

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet("/api/current-user");
    if (response.error) {
      throw response.error;
    }
    window.localStorage.setItem(
      "brandhub-silver-role",
      response.data.role || "super"
    );
    const user = mapUser(response.data);
    let organization = null;
    let org = await axiosGet("/api/organizations");
    if (org.error) {
      throw org.error;
    }
    organization = mapOrgs(org.data, response.data.organization?.id);

    if (organization.preferProductGridView) {
      dispatch(setProductView("grid"));
    }

    if (!permissions.externalRoles.includes(user.role)) {
      if (organization.usesCostCenters) {
        dispatch(fetchCurrentUserCostCenters(user.id));
      }
    }
    dispatch(getUserSuccess({ user, organization }));
    if (!organization.id && organization.availableOrgs.length > 0) {
      dispatch(changeOrganization(organization.availableOrgs[0].id));
    }
  } catch (err) {
    console.error(err);
    dispatch(setLogInFailure({ error: err.toString() }));
    dispatch(setRedirect({ redirectBool: true, url: "/" }));
    dispatch(removeUser());
    dispatch(setFailure());
  }
};

export const updateCurrentUser =
  (attributes, maybeCallback) => async (dispatch, getState) => {
    try {
      dispatch(setIsUpdateLoading());
      const response = await axiosPatch("/api/current-user", {
        data: {
          attributes: {
            "organization-id": getState().currentUser.organization.id,
            ...kebabCaseKeys(attributes),
          },
        },
      });
      if (response.error) {
        throw response.error;
      }

      const mappedData = mapUser(response.data);

      dispatch(updateUserSuccess({ user: mappedData }));
    } catch (err) {
      dispatch(setFailure());
      dispatch(
        setError({ error: err.toString(), source: "Update current user" })
      );
    } finally {
      maybeCallback?.();
    }
  };

export const loginWithToken = (token, exp) => async (dispatch) => {
  try {
    dispatch(setLoginLoading());
    const response = await loginWithAuthToken(token, exp);
    if (response.error) {
      throw response.error;
    }
    dispatch(
      setLoginSuccess({
        expires: exp,
        type: "auth0",
      })
    );
  } catch (err) {
    // dispatch(setLogInFailure({ error: err.toString() }));
    // dispatch(setError({ error: err.toString(), source: "Users" }));
  }
};

export const changeOrganization = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosPost("/api/current-user/update-organization", {
      data: { "organization-id": +id },
    });
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapUser(response.data);
    dispatch(
      getUserSuccess({ user: mappedData, organization: null, orgId: id })
    );
    dispatch(setRefetchData({ value: true }));
  } catch (err) {
    dispatch(setFailure());
    dispatch(setError({ error: err.toString(), source: "Users" }));
  }
};

export const updateOrganization =
  (attrs, countries, id, maybeCallback) => async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      const patchData = buildOrgPatch(attrs, countries, id);
      const response = await axiosPatch(`/api/organizations/${id}`, patchData);
      if (response.error) {
        throw response.error;
      }
      const mappedData = mapOrgs([response.data], id);
      dispatch(updateOrgSuccess({ org: mappedData }));
      maybeCallback?.();
    } catch (err) {
      dispatch(setFailure());
      dispatch(setError({ error: err.toString(), source: "Organization" }));
    }
  };

export const fetchSupplierCounts = () => async (dispatch) => {
  try {
    const newFilter = separateByComma("status", ["submitted"]);
    const inProgressFilter = separateByComma("status", ["in-progress"]);
    const newResponse = await axiosGetWithNext(
      `/api/purchase-orders?${newFilter}`
    );
    if (newResponse.error) {
      throw newResponse.error;
    }
    const inProgressResponse = await axiosGetWithNext(
      `/api/purchase-orders?${inProgressFilter}`
    );
    if (inProgressResponse.error) {
      throw inProgressResponse.error;
    }
    const newCount = newResponse.data.totalEntries ?? 0;
    const inProgressCount = inProgressResponse.data.totalEntries ?? 0;
    dispatch(
      updateSupplierCounts({
        newCount: newCount,
        inProgressCount: inProgressCount,
      })
    );
  } catch (err) {
    dispatch(setFailure());
    dispatch(setError({ error: err.toString(), source: "Supplier Counts" }));
  }
};

export const updateHasViewed = (field) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    const postData = {
      data: {
        attributes: {
          [field]: true,
        },
      },
    };
    const response = await axiosPost(
      "/api/current-user/update-has-viewed",
      postData
    );
    if (response.error) throw response.error;
    dispatch(updateHasViewedSuccess({ field: field }));
  } catch (err) {
    dispatch(setFailure());
    dispatch(setError({ error: err.toString(), source: "Favorite Items" }));
  }
};

export const updateFavoriteItems = (itemIds) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildFavoriteItemPatch(itemIds.map(Number));
    const response = await axiosPatch("/api/current-user", patchData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapUser(response.data);
    dispatch(updateUserSuccess({ user: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure());
    dispatch(setError({ error: err.toString(), source: "Favorite Items" }));
    dispatch(patchFailure());
  }
};

export const toggleFavoriteItem = (itemId) => async (dispatch, getState) => {
  try {
    const { favoriteItemIds, ...user } = getState().currentUser;
    const newFavoriteItemIds = xor(favoriteItemIds, [+itemId]);
    dispatch(
      updateUserSuccess({ user: { user, favoriteItemIds: newFavoriteItemIds } })
    );
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildFavoriteItemPatch(newFavoriteItemIds);
    const response = await axiosPatch("/api/current-user", patchData);
    if (response.error) {
      dispatch(updateUserSuccess({ user: { user, favoriteItemIds } }));
      throw response.error;
    }
    const mappedData = mapUser(response.data);
    dispatch(updateUserSuccess({ user: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure());
    dispatch(setError({ error: err.toString(), source: "Favorite Items" }));
    dispatch(patchFailure());
  }
};

export const updateDashboardOptions = (options, role) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    let newOptions = [];
    let errors = [];

    for (let i = 0; i < options.length; i++) {
      let currentOption = options[i];
      let patchData = buildDashboardOptionPatch(currentOption);
      let response = await axiosPatch(
        `/api/dashboard-options/${currentOption.id}`,
        patchData
      );
      if (response.error) {
        errors = errors.concat(currentOption.displayName);
      }
      let mappedOption = mapDashboardOptions([response.data])[0];
      newOptions = newOptions.concat(mappedOption);
    }
    if (newOptions.length > 0) {
      dispatch(
        updateDashboardOptionsSuccess({ options: newOptions, role: role })
      );
    }
    if (errors.length > 0) {
      dispatch(
        setError({
          error: `The following options could not be updated: ${errors.join(
            ", "
          )}`,
          source: "Dashboard Options",
        })
      );
    }
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure());
    dispatch(setError({ error: err.toString(), source: "Dashboard Options" }));
    dispatch(patchFailure());
  }
};

export const updateChannelUser =
  (id, attributes, maybeCallback) => async (dispatch) => {
    try {
      const res = await axiosPatch(`/api/channel-users/${id}`, {
        data: { attributes: kebabCaseKeys(attributes) },
      });
      if (res.error) throw res.error;
      dispatch(updateChannelUserSuccess(mapchannelUsers([res.data])[0]));
      dispatch(patchSuccess());
    } catch (error) {
      dispatch(setFailure());
      dispatch(
        setError({ error: error.toString(), source: "Update User Channel" })
      );
      dispatch(patchFailure());
    } finally {
      maybeCallback?.();
    }
  };
