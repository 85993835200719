/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { memo } from "react";

import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";

import clsx from "clsx";
import format from "date-fns/format";
import PropTypes from "prop-types";

import { DatePicker } from "@utils/forms";

import MemoNumberField from "../../Utility/MemoNumberField";
import MemoTextArea from "../../Utility/MemoTextArea";

const GeneralPanel = ({
  classes,
  type,
  formData,
  handleFormUpdate,
  handleSubmit,
  promotion,
}) => {
  if (type === "edit" && !promotion) {
    return (
      <>
        <br />
        <br />
        <CircularProgress color="secondary" />
      </>
    );
  }

  return (
    <>
      <div
        className={clsx(classes.fullWidthCenterColumn, classes.settingsMargin)}
        style={{ textAlign: "center" }}
      >
        <Typography className={classes.bodyText} color="textSecondary">
          <em>
            The promotion description is how users will see each promotion, so
            it is helpful to include details about what is being discounted, and
            what the discount is.
          </em>
        </Typography>
      </div>
      <MemoTextArea
        rows={2}
        value={formData.description}
        setValue={handleFormUpdate}
        formKey="description"
        label="Description"
        fullWidth={true}
        componentClass={classes.settingsMargin}
      />
      <div tw="grid gap-4 lg:grid-cols-2">
        <DatePicker
          color="secondary"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="start-date"
          label="Start Date"
          value={formData.startDate}
          onChange={(value) => {
            handleFormUpdate("startDate", format(value, "MM/dd/yyyy"));
          }}
        />

        <DatePicker
          color="secondary"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="expiration-date"
          label="Expiration Date"
          value={formData.expirationDate}
          onChange={(value) => {
            handleFormUpdate("expirationDate", format(value, "MM/dd/yyyy"));
          }}
        />
        <FormControl size="small" variant="outlined">
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            label="Type"
            lableid="type-label"
            id="type-select"
            value={formData.type}
            onChange={(evt) => {
              if (evt.target.value === "order") {
                handleFormUpdate("maxQty", "");
              }
              handleFormUpdate("type", evt.target.value);
            }}
          >
            <MenuItem value={"order"}>Order Promotion</MenuItem>
            <MenuItem value={"item"}>Item Promotion</MenuItem>
          </Select>
        </FormControl>
        {/* <FormControl
          size="small"
          variant="outlined"
        >
          <InputLabel id="redemption-type-label">Redemption Type</InputLabel>
          <Select
            label="Redemption Type"
            lableid="redemption-type-label"
            id="redemption-type-select"
            value={formData.redemptionType}
            onChange={(evt) =>
              handleFormUpdate("redemptionType", evt.target.value)
            }
          >
            <MenuItem value={"assignment"}>Assignment</MenuItem>
            <MenuItem value={"code"}>Promotion Code</MenuItem>
          </Select>
        </FormControl> */}
        <FormControl size="small" variant="outlined">
          <InputLabel id="discount-type-label">Discount Type</InputLabel>
          <Select
            label="Discount Type"
            lableid="discount-type-label"
            id="discount-type-select"
            value={formData.discountType}
            onChange={(evt) => {
              if (evt.target.value === "dollar") {
                handleFormUpdate("percentOff", "");
              }
              if (evt.target.value === "percent") {
                handleFormUpdate("dollarsOff", "");
              }
              if (evt.target.value === "free-shipping") {
                handleFormUpdate("percentOff", "");
                handleFormUpdate("dollarsOff", "");
              }
              handleFormUpdate("discountType", evt.target.value);
            }}
          >
            <MenuItem value={"dollar"}>Dollars Off</MenuItem>
            <MenuItem value={"percent"}>Percent Off</MenuItem>
            <MenuItem value={"free-shipping"}>Free Shipping</MenuItem>
          </Select>
        </FormControl>
        <MemoNumberField
          value={
            formData.discountType === "dollar"
              ? formData.dollarsOff
              : formData.percentOff
          }
          setValue={handleFormUpdate}
          formKey={
            formData.discountType === "dollar" ? "dollarsOff" : "percentOff"
          }
          label={
            formData.discountType === "dollar"
              ? "Dollars Off"
              : formData.discountType === "percent"
                ? "Percent Off"
                : "---"
          }
          fullWidth={false}
          disabled={formData.discountType === "free-shipping"}
        />
        <MemoNumberField
          value={formData.maxQty}
          setValue={handleFormUpdate}
          formKey="maxQty"
          label="Max Quantity"
          fullWidth={false}
          disabled={formData.type === "order" || !formData.isOneTimeUse}
        />
        <div tw="flex gap-4 items-center">
          <Typography className={classes.headerText}>One Time Use:</Typography>
          <div className={classes.center}>
            <Typography
              className={classes.bodyText}
              style={{ margin: "0 10px 0 10px" }}
              color="textSecondary"
            >
              No
            </Typography>
            <Switch
              checked={formData.isOneTimeUse}
              onChange={() => {
                if (formData.isOneTimeUse) {
                  handleFormUpdate("maxQty", "");
                }
                handleFormUpdate("isOneTimeUse", !formData.isOneTimeUse);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography
              className={classes.bodyText}
              style={{ margin: "0 10px 0 10px" }}
              color="textSecondary"
            >
              Yes
            </Typography>
          </div>
        </div>
        {formData.type === "order" && (
          <FormControl size="small" variant="outlined">
            <InputLabel id="limit-order-type-label">
              Limit Order Type
            </InputLabel>
            <Select
              label="Limit Order Type"
              lableid="limit-order-type-label"
              value={formData.orderType}
              onChange={(evt) => {
                handleFormUpdate("orderType", evt.target.value);
              }}
            >
              <MenuItem value={""}>No Restrictions</MenuItem>
              <MenuItem value={"pre-order"}>Pre Order</MenuItem>
              <MenuItem value={"inventory"}>Inventory</MenuItem>
              <MenuItem value={"on-demand"}>On Demand</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
      {type === "edit" && (
        <>
          <br />
          <br />
          <Button
            className={clsx(classes.button, classes.settingsMargin)}
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            style={{ minWidth: "62px" }}
          >
            SAVE
          </Button>
        </>
      )}
    </>
  );
};

GeneralPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  formData: PropTypes.object.isRequired,
  handleFormUpdate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  promotion: PropTypes.object,
};

export default memo(GeneralPanel);
