import React from "react";

import CancelIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import PropTypes from "prop-types";

import { OpaqueCard } from "../../StyledComponents";
import ReactQueryTable from "../../Table/ReactQueryTable";

const AddressTable = ({
  addressList,
  isValidating,
  updateFaveListAddresses,
}) => {
  const handleDelete = (addId) => {
    const newAddressIds = addressList.addresses
      .filter((a) => {
        return a.id !== addId;
      })
      .map((address) => address.id);
    updateFaveListAddresses(addressList.id, newAddressIds);
  };

  return (
    <>
      <OpaqueCard tw="p-0 overflow-hidden relative">
        <ReactQueryTable
          rows={addressList.addresses}
          isValidating={isValidating}
          columns={[
            { id: "name", label: "Name", sort: "name" },
            { id: "streetAddress1", label: "Street Address One" },
            { id: "city", label: "City" },
            {
              id: "state.code",
              label: "State/Region",
              render: (stateCode, row) => stateCode ?? row.region,
            },
            { id: "zip", label: "ZIP" },
            { id: "phoneNumber", label: "Phone Number" },
            {
              id: "remove",
              label: "Remove Favorite",
              render: (_, address) => (
                <Tooltip title="Remove Favorite">
                  <IconButton
                    onClick={() => handleDelete(address.id)}
                    size="large"
                  >
                    <CancelIcon fontSize="small" color="inherit" />
                  </IconButton>
                </Tooltip>
              ),
            },
          ]}
        />
      </OpaqueCard>
    </>
  );
};

AddressTable.propTypes = {
  addressList: PropTypes.object.isRequired,
  isValidating: PropTypes.bool,
};

export default React.memo(AddressTable);
