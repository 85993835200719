/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
} from "@mui/material";

import { format } from "date-fns";

import { formats } from "@utility/dateTime";

import { useBeaconProgram } from "../../hooks/beaconHooks";
import { OpaqueCard } from "../StyledComponents";

const BeaconList = () => {
  const { programId, state, variantSku } = useParams();
  const { hash } = useLocation();
  const { beacons, isLoading } = useBeaconProgram({
    programId,
    state,
    variantSku,
  });

  const [expanded, setExpanded] = useState(hash);

  useEffect(() => {
    setExpanded(hash);
  }, [hash]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <OpaqueCard tw="p-0 h-full">
      <div tw="p-2 pb-8 overflow-y-auto max-h-full">
        {isLoading && <CircularProgress size={24} tw="p-4" />}
        {beacons.map((beacon, i) => (
          <Accordion
            key={i}
            expanded={expanded === `#${beacon.id}`}
            onChange={handleChange(`#${beacon.id}`)}
          >
            <Link key={i} to={`#${beacon.id}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreRounded />}
                aria-controls={`panel${i}-content`}
                id={`panel${i}-header`}
                css={[
                  tw`px-3 py-2 transition rounded-lg hover:bg-neutral-100 text-neutral-700`,
                  hash === `#${beacon.id}` && tw`border-2 border-primary-100`,
                ]}
              >
                <div tw="flex flex-col">
                  <h3 tw="text-base font-bold leading-tight">
                    {beacon.storeName ?? "UNKNOWN ADDRESS, SEE MAP FOR DETAILS"}
                  </h3>

                  <div tw="2xl:(flex-row justify-between items-baseline gap-3)">
                    <div tw="text-sm text-neutral-500">
                      {beacon.storeStreetAddress
                        ? `${beacon.storeStreetAddress}, ${beacon.storeCity} ${
                            beacon.storeStateCode
                          }, ${beacon.storeZip?.slice(0, 5)}`
                        : `LAT/LONG: ${beacon.lat.toFixed(
                            4
                          )}, ${beacon.lng.toFixed(4)}`}
                    </div>
                    <div tw="text-sm text-primary-600">
                      Last pinged{" "}
                      {format(
                        new Date(beacon.timestamp),
                        formats.keyboardDateTime
                      )}
                    </div>
                  </div>
                </div>
              </AccordionSummary>
            </Link>
            <AccordionDetails>
              <Typography component="div" tw="text-sm text-neutral-500">
                {/* Check if the first and second stores returned are the same */}
                {beacon.firstStoreStreetAddress + beacon.firstStoreZip !==
                beacon.storeStreetAddress + beacon.storeZip ? (
                  <div tw="flex flex-col">
                    <div tw="text-neutral-500 italic">Previously at:</div>
                    <h3 tw="text-base font-bold leading-tight">
                      {beacon.firstStoreName}
                    </h3>
                    <div tw="2xl:(flex-row justify-between items-baseline gap-3)">
                      <div tw="text-sm text-neutral-500">
                        {`${beacon.firstStoreStreetAddress}, ${
                          beacon.firstStoreCity
                        } ${
                          beacon.firstStoreStateCode
                        }, ${beacon.firstStoreZip?.slice(0, 5)}`}
                      </div>
                      <div tw="text-sm text-primary-600">
                        First pinged{" "}
                        {format(
                          new Date(beacon.firstTimestamp),
                          formats.keyboardDateTime
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>No additional details available</>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </OpaqueCard>
  );
};

export default BeaconList;
