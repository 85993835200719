import { useEffect } from "react";

import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

const siteIds = {
  help: "7900191b-dacc-46ad-86e3-2704c6e619bf",
  supplierHelp: "b700dfea-cf4c-40c9-8572-bd5e07f4bf2a",
  test: "e6b82cd1-a6f2-474a-99de-379bb37555f8",
};

const useHelpScoutBeacon = () => {
  const roleIs = useRoleIs();

  const beaconId = roleIs(permissions.externalRoles, "select-account-manager")
    ? siteIds.supplierHelp
    : siteIds.help;

  useEffect(() => {
    // from helpscout beacon script
    (function (e, t, n) {
      function a() {
        const e = t.getElementsByTagName("script")[0];
        const n = t.createElement("script");
        n.type = "text/javascript";
        n.async = true;
        n.src = "https://beacon-v2.helpscout.net";
        e.parentNode!.insertBefore(n, e);
      }
      if (
        ((e.Beacon = n =
          function (t, n, a) {
            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
          }),
        (n.readyQueue = []),
        "complete" === t.readyState)
      )
        return a();
      e.addEventListener("load", a);
    })(window, document, window.Beacon || function () {});
  }, []);
  useEffect(() => {
    window.Beacon("init", beaconId);
    window.Beacon("show-message", "cc739ce4-1456-437c-bd52-c0ff83862a0c");
  }, [beaconId]);
};

export default useHelpScoutBeacon;
